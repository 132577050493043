import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import FirebaseUsage from "../../../../firebase/firebase.usage";


export default function PowerBiDashboard() {

    console.log(FirebaseUsage.getCollection('contracts'))

    return (
        <PowerBIEmbed
            embedConfig = {{
                type: 'report',   // Since we are reporting a BI report, set the type to report
                id: '34d50f91-1016-4a67-ae98-6997f9d55426', // Add the report Id here
                embedUrl: "https://app.powerbi.com/reportEmbed?reportId=34d50f91-1016-4a67-ae98-6997f9d55426&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVLLVNPVVRILUMtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d", // Add the embed url here
                accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IllUY2VPNUlKeXlxUjZqekRTNWlBYnBlNDJKdyIsImtpZCI6IllUY2VPNUlKeXlxUjZqekRTNWlBYnBlNDJKdyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvOTEwMzdkZGQtY2RkMi00MDk0LThiNTAtMDQwODIwMWQ0OTk4LyIsImlhdCI6MTczNzcxOTYyMCwibmJmIjoxNzM3NzE5NjIwLCJleHAiOjE3Mzc3MjM2NDYsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVlFBcS84WkFBQUFPVWFnSzRWdDYwNTVtRzhLUU5aV3pDSVkxZzhQdzgwU3BsUHpCRkJmaHhwV3RIdHRYclVxOHl5NndGTFh0VEVOZ0d4RFJnVzNPK2x6OEs4VUxNczVwL2NIK3BoMlBERTY1aFVxRmRzdHJpZz0iLCJhbXIiOlsicHdkIiwibWZhIl0sImFwcGlkIjoiODcxYzAxMGYtNWU2MS00ZmIxLTgzYWMtOTg2MTBhN2U5MTEwIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJGaXR6c2ltbW9ucyIsImdpdmVuX25hbWUiOiJKb2huIiwiaWR0eXAiOiJ1c2VyIiwiaXBhZGRyIjoiNzkuNzguMTk0LjE4MyIsIm5hbWUiOiJKb2huIEZpdHpzaW1tb25zIiwib2lkIjoiZWYxODNmOTktY2U5NS00ZTBjLThhNzAtMTI3YjM1Yjc0OTdlIiwicHVpZCI6IjEwMDMyMDAzOTJFMTg2MEQiLCJyaCI6IjEuQWE0QTNYMERrZExObEVDTFVBUUlJQjFKbUFrQUFBQUFBQUFBd0FBQUFBQUFBQUN0QUNTdUFBLiIsInNjcCI6InVzZXJfaW1wZXJzb25hdGlvbiIsInNpZCI6ImIxZTM5YWM1LTM0ZmMtNGUyNC04MDg0LTFiYzAyMWZkNjRmYiIsInNpZ25pbl9zdGF0ZSI6WyJrbXNpIl0sInN1YiI6IjhUUnhaOEJkVG92Q3Eza2VRSXYwMi15T3F4MXhKZ2FhTkcwSnpjZ3lhcjgiLCJ0aWQiOiI5MTAzN2RkZC1jZGQyLTQwOTQtOGI1MC0wNDA4MjAxZDQ5OTgiLCJ1bmlxdWVfbmFtZSI6ImpvaG4uZml0enNpbW1vbnNAaGVhZHBvaW50cHJvamVjdHMuY29tIiwidXBuIjoiam9obi5maXR6c2ltbW9uc0BoZWFkcG9pbnRwcm9qZWN0cy5jb20iLCJ1dGkiOiJFcXQyYzVDQzZFZWIwNHVKdFUwTkFBIiwidmVyIjoiMS4wIiwid2lkcyI6WyI2MmU5MDM5NC02OWY1LTQyMzctOTE5MC0wMTIxNzcxNDVlMTAiLCJiNzlmYmY0ZC0zZWY5LTQ2ODktODE0My03NmIxOTRlODU1MDkiXSwieG1zX2lkcmVsIjoiMSAxNCJ9.hMVfMRLeIJdFjAcPCO0bToJ5ICruIVzW4r8S-hya9DUZkjvaIXRkEtmJQz1VE2HVGOScX6wKPl9jDuBBHEVBjkJICE7oZu-hKYxZkljAAuBpmqqkP8Xn04OtW7X5SmACsIk_AJdqAFmg87TUl5zaJaY1uRHdaHiIh3TYsU4VqUSF2LFkSjbO2f3FimuTD4OHpC4DEUJEIcyNDa5NgWrZyAWa27zAQIUPm7WonggSOr1NIP62cuC1Yaqvy7OR3mqyfel4VJJxad6qyHPDa54L2I2W6tUCvwT09lF1h7_9lpVnoNC4iuYXVpxWVXZCugDNvokEkDASJyxP_Gbqwn6WbA', // Add the access token here
                tokenType: models.TokenType.Aad, // Since we are using an Azure Active Directory access token, set the token type to Aad
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: true
                        }
                    },
                    background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = {
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event?.detail);}],
                    ['visualClicked', () => console.log('visual clicked')],
                    ['pageChanged', (event) => console.log(event)],
                ])
            }

            cssClassName = { "bi-embedded" }

            getEmbeddedComponent = { (embeddedReport) => {
                //@ts-ignore
                window.report = embeddedReport; // save report in window object
            }}
        />
    )
}