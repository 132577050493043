import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';


export default function PowerBiDashboard() {

    return (
        <PowerBIEmbed
            embedConfig = {{
                type: 'report',   // Since we are reporting a BI report, set the type to report
                id: '34d50f91-1016-4a67-ae98-6997f9d55426', // Add the report Id here
                embedUrl: "https://app.powerbi.com/reportEmbed?reportId=34d50f91-1016-4a67-ae98-6997f9d55426&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVLLVNPVVRILUMtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d", // Add the embed url here
                accessToken: 'b7dec9d7307cb72ef7a6', // Add the access token here
                tokenType: models.TokenType.Aad, // Since we are using an Azure Active Directory access token, set the token type to Aad
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: true
                        }
                    },
                    background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = {
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event?.detail);}],
                    ['visualClicked', () => console.log('visual clicked')],
                    ['pageChanged', (event) => console.log(event)],
                ])
            }

            cssClassName = { "bi-embedded" }

            getEmbeddedComponent = { (embeddedReport) => {
                //@ts-ignore
                window.report = embeddedReport; // save report in window object
            }}
        />
    )
}