import React, {useMemo} from 'react';
import { Group } from '@visx/group';
import { curveBasis } from '@visx/curve';
import { LinePath } from '@visx/shape';
import { Threshold } from '@visx/threshold';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import cityTemperature, { CityTemperature } from '@visx/mock-data/lib/mocks/cityTemperature';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import {
    useActiveDataVersionSelector,
    useActiveProjectSelector,
    useSprintDataSelector
} from "../../../../store/selectors/project.selectors";
import moment from "moment";
import {SprintCategory} from "../../../../models/sprint.model";
import TaskModel from "../../../../models/responses/task.model";
import {Marker} from "@react-google-maps/api";
import { localPoint } from '@visx/event';

type TooltipData = {
    bar: any;
    key: string;
    index: number;
    height: number;
    width: number;
    x: number;
    y: number;
    color: string;
    id: string;
};

const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: 'white',
};

export type BarsProps = {
    width: number;
    height: number;
    allTasks: TaskModel[];
    events?: boolean;
    margin?: { top: number; right: number; bottom: number; left: number };
    showingStarts?: boolean;
};
export const background = '#f3f3f3';

const targetColour = '#D9001BFF';
const actualColour = '#0F9D58FF';
const actualColourFinish = '#4285F4FF';
const averageColour = '#F4B400FF';

const defaultMargin = { top: 5, right: 20, bottom: 30, left: 40 };

let tooltipTimeout: number;

export default function WeeklySprintChart({ width, height, allTasks, events = false, margin = defaultMargin, showingStarts = true }: BarsProps) {
    // bounds

    // const allTasks = useAllTaskListSelector();
    const activeProject = useActiveProjectSelector();
    const currentSprintDate = moment(activeProject!.lastSnapshotTimestamp!.toDate().setHours(0, 0, 0, 0));
    const startSprintDate = moment(currentSprintDate).subtract(12, 'weeks');
    const startSprintDayOfWeek = startSprintDate.day();
    const activeDataVersion = useActiveDataVersionSelector();
    const sprintData = useSprintDataSelector();
    const customMoment = moment.updateLocale('en', {
        week: {
            dow: startSprintDayOfWeek,
        }
    })
    const days = Array(7).fill(0).map((_, i) =>
        startSprintDayOfWeek + i > 6 ? startSprintDayOfWeek + i - 7 : startSprintDayOfWeek + i)

    const sprintChartData = useMemo(() => {
        let sprintDataTwelve = sprintData.slice(Math.max(sprintData.length - 12, 0), sprintData.length);
        let sprintValidator = new Map();
        sprintDataTwelve.forEach(sprint => {
            const weekStart = moment(sprint.periodStart.toDate()).startOf('week');
            const weekString = weekStart.format('YYYY-MM-DD');
            sprintValidator.set(weekString, new Map(sprint.sprintTasks.map(task => [task, true])));
        })

        let sprintAveragesData = {};
        for (let i = 0; i <= 12; i++) {
            const weekStart = moment(startSprintDate).add(i, 'weeks');
            const weekString = weekStart.format('YYYY-MM-DD');
            sprintAveragesData[weekString] = {
                starts: {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0},
                finishes: {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0},
            }
        }

        let finalSprintData: any = {
            actual: Array(7).fill(0).map((_, i) => ({day: i, startCount: 0, finishCount: 0})),
            averages: {starts: Array(7).fill(0), finishes: Array(7).fill(0)},
            standardDeviation: {starts: Array(7).fill(0), finishes: Array(7).fill(0)},
            plan: Array(7).fill(0).map((_, i) => ({day: i, startCount: 0, finishCount: 0})),
        }
        allTasks.forEach(task => {
            if (task.act_start_date) {
                if (task.act_start_date.toDate() >= startSprintDate.toDate()) {
                    const weekDate = moment(task.act_start_date.toDate()).startOf('week')
                    const weekString = weekDate.format('YYYY-MM-DD');
                    const dayOfWeek = task.act_start_date.toDate().getDay();
                    if (sprintValidator.has(weekString) && sprintValidator.get(weekString).has(task.task_code)) {
                        sprintAveragesData[weekString].starts[dayOfWeek] += 1;
                    }
                }}
            if (task.act_end_date) {
                if (task.act_end_date.toDate() >= startSprintDate.toDate()) {
                    const weekDate = moment(task.act_end_date.toDate()).startOf('week')
                    const weekString = weekDate.format('YYYY-MM-DD');
                    const dayOfWeek = task.act_end_date.toDate().getDay();
                    if (sprintValidator.has(weekString) && sprintValidator.get(weekString).has(task.task_code)) {
                        sprintAveragesData[weekString].finishes[dayOfWeek] += 1;
                    }
                }
            } else if (task.declaredCompleteTimestamp) {
                if (task.declaredCompleteTimestamp.toDate() >= startSprintDate.toDate()) {
                    const weekDate = moment(task.declaredCompleteTimestamp.toDate()).startOf('week')
                    const weekString = weekDate.format('YYYY-MM-DD');
                    const dayOfWeek = task.declaredCompleteTimestamp.toDate().getDay();
                    if (sprintValidator.has(weekString) && sprintValidator.get(weekString).has(task.task_code)) {
                        sprintAveragesData[weekString].finishes[dayOfWeek] += 1;
                    }
            }}

            if (task.sprint === activeDataVersion.version.dataVersionId) {
                if (task.act_start_date && task.act_start_date.toDate() > currentSprintDate.toDate()) {
                    finalSprintData.actual[task.act_start_date.toDate().getDay()].startCount += 1;
                }
                if (task.act_end_date && task.act_end_date.toDate() > currentSprintDate.toDate()) {
                    finalSprintData.actual[task.act_end_date.toDate().getDay()].finishCount += 1;
                }
                else if (task.declaredCompleteTimestamp && task.declaredCompleteTimestamp.toDate() > currentSprintDate.toDate()) {
                    finalSprintData.actual[task.declaredCompleteTimestamp.toDate().getDay()].finishCount += 1;
                }

                if (task.sprintCategory === SprintCategory.START) {
                    if (task.forecastDate && task.forecastDate.toDate() > currentSprintDate.toDate()) {
                        finalSprintData.plan[task.forecastDate.toDate().getDay()].startCount += 1;
                        console.log(task)
                    }
                } else if (task.sprintCategory === SprintCategory.FINISH) {
                    if (task.forecastFinish && task.forecastFinish.toDate() > currentSprintDate.toDate()) {
                        finalSprintData.plan[task.forecastFinish.toDate().getDay()].finishCount += 1;
                    }
                } else if (task.sprintCategory === SprintCategory.START_AND_FINISH) {
                    if (task.forecastDate && task.forecastDate.toDate() > currentSprintDate.toDate()) {
                        finalSprintData.plan[task.forecastDate.toDate().getDay()].startCount += 1;
                    }
                    if (task.forecastFinish && task.forecastFinish.toDate() > currentSprintDate.toDate()) {
                        finalSprintData.plan[task.forecastFinish.toDate().getDay()].finishCount += 1;
                    }
                }
            }
        })

        for (let key in sprintAveragesData) {
            for (let i = 0; i < 7; i++) {
                finalSprintData.averages.starts[i] += sprintAveragesData[key].starts[i];
                finalSprintData.averages.finishes[i] += sprintAveragesData[key].finishes[i];
            }
        }

        for (let i = 0; i < 7; i++) {
            finalSprintData.averages.starts[i] = Math.ceil(finalSprintData.averages.starts[i] / 12);
            finalSprintData.averages.finishes[i] = Math.ceil(finalSprintData.averages.finishes[i] / 12);
        }

        for (let key in sprintAveragesData) {
            for (let i = 0; i < 7; i++) {
                finalSprintData.standardDeviation.starts[i] += Math.pow(sprintAveragesData[key].starts[i] - finalSprintData.averages.starts[i], 2);
                finalSprintData.standardDeviation.finishes[i] += Math.pow(sprintAveragesData[key].finishes[i] - finalSprintData.averages.finishes[i], 2);
            }
        }

        for (let i = 0; i < 7; i++) {
            finalSprintData.standardDeviation.starts[i] = Math.floor(Math.sqrt(finalSprintData.standardDeviation.starts[i] / 12));
            finalSprintData.standardDeviation.finishes[i] = Math.floor(Math.sqrt(finalSprintData.standardDeviation.finishes[i] / 12));
        }

        finalSprintData.startLineData = {
            actual: [0, ...days.map((day) => finalSprintData.actual[day].startCount).map((sum => value => sum += value)(0))],
            plan: [0, ...days.map((day) => finalSprintData.plan[day].startCount).map((sum => value => sum += value)(0))],
            average: [0, ...days.map((day) => finalSprintData.averages.starts[day]).map((sum => value => sum += value)(0))],
            upper: [0, ...days.map((day) => finalSprintData.averages.starts[day] + finalSprintData.standardDeviation.starts[day]).map((sum => value => sum += value)(0))],
            lower: [0, ...days.map((day) => finalSprintData.averages.starts[day] - finalSprintData.standardDeviation.starts[day]).map((sum => value => sum += value)(0))]}

        finalSprintData.finishLineData = {
            actual: [0, ...days.map((day) => finalSprintData.actual[day].finishCount).map((sum => value => sum += value)(0))],
            plan: [0, ...days.map((day) => finalSprintData.plan[day].finishCount).map((sum => value => sum += value)(0))],
            average: [0, ...days.map((day) => finalSprintData.averages.finishes[day]).map((sum => value => sum += value)(0))],
            upper: [0, ...days.map((day) => finalSprintData.averages.finishes[day] + finalSprintData.standardDeviation.finishes[day]).map((sum => value => sum += value)(0))],
            lower: [0, ...days.map((day) => finalSprintData.averages.finishes[day] - finalSprintData.standardDeviation.finishes[day]).map((sum => value => sum += value)(0))]}

        return finalSprintData;
    }, [allTasks]);

    if (width < 10) return null;

    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    // scales
    const timeScale = scaleTime<number>({
        // define only 7 days
        domain: [currentSprintDate.toDate(), moment(currentSprintDate).add(1, 'week').toDate()],
    });
    const taskCountScale = scaleLinear<number>({
        domain: [
            0,
            Math.max(sprintChartData.startLineData.upper[sprintChartData.startLineData.upper.length - 1],
                sprintChartData.finishLineData.upper[sprintChartData.finishLineData.upper.length - 1],
                sprintChartData.startLineData.plan[sprintChartData.startLineData.plan.length - 1],
                sprintChartData.finishLineData.plan[sprintChartData.finishLineData.plan.length - 1])
        ],
        nice: true,
    });

    timeScale.range([0, xMax]);
    taskCountScale.range([yMax, 0]);

    const thresholdData = showingStarts ? Array(8).fill(0).map((_, i) =>
        ({upper: sprintChartData.startLineData.upper[i], lower: sprintChartData.startLineData.lower[i]})) :
        Array(8).fill(0).map((_, i) =>
            ({upper: sprintChartData.finishLineData.upper[i], lower: sprintChartData.finishLineData.lower[i]}));

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        // TooltipInPortal is rendered in a separate child of <body /> and positioned
        // with page coordinates which should be updated on scroll. consider using
        // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
        scroll: true,
    });
    const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
        useTooltip<TooltipData>();

    const handleTooltip = (event, d, i, key, colour, id: string) => {
        // if (tooltipTimeout) clearTimeout(tooltipTimeout);
        const clientY = event.clientY;
        const left = i ? timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate()) : event.clientX;
        showTooltip({
            tooltipData: {
                id: id,
                bar: {x: left, width: 0, data: d, date: i ? moment(currentSprintDate.toDate()).add(i, 'days').format('DD-MMM-YYYY') : null},
                key: key,
                index: i,
                height: 0,
                width: 0,
                x: 0,
                y: 0,
                color: colour},
            tooltipTop: clientY,
            tooltipLeft: left,
        });
    };

    const handleTooltipHide = () => {
        hideTooltip();
        // tooltipTimeout = window.setTimeout(() => {
        //     hideTooltip();
        // }, 300);
    }

    return (
        <div>
            <svg width={width} height={height}>
                <rect x={0} y={0} width={width} height={height} fill={"white"} />
                <GridRows scale={taskCountScale} width={xMax + 60} left={0} top={margin.top} height={yMax} stroke="#e0e0e0" />
                {showingStarts ? <Group left={margin.left} top={margin.top}>
                    {/*<GridRows scale={taskCountScale} width={xMax + 60} left={0} height={yMax} stroke="#e0e0e0" />*/}
                    <GridColumns scale={timeScale} width={xMax} height={yMax} stroke="#e0e0e0" numTicks={7}/>
                    <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0" />
                    <AxisBottom top={yMax} scale={timeScale} numTicks={7} />
                    <AxisLeft scale={taskCountScale} />
                    <Threshold
                        id={`${Math.random()}`}
                        data={thresholdData}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y0={(d) => taskCountScale(d.lower) ?? 0}
                        y1={(d) => taskCountScale(d.upper) ?? 0}
                        clipAboveTo={0}
                        clipBelowTo={yMax}
                        // curve={curveBasis}
                        belowAreaProps={{
                            fill: averageColour,
                            fillOpacity: tooltipData && tooltipData.id === 'average' ? 0.4 : 0.2,
                            onMouseOver: (e) => handleTooltip(e, null, null, '12-week average achieved (±σ)', averageColour, 'average'),
                            onMouseLeave: () => handleTooltipHide()
                        }}
                    />
                    <LinePath
                        data={sprintChartData.startLineData.average}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke={averageColour}
                        strokeWidth={tooltipData && tooltipData.id === 'average' ? 3 : 1.5}
                        strokeOpacity={0.8}
                        strokeDasharray="1,2"
                    />
                    <LinePath
                        data={sprintChartData.startLineData.plan}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke={targetColour}
                        strokeWidth={2}
                        strokeOpacity={0.8}
                        // strokeDasharray="1,2"
                    />
                    {sprintChartData.startLineData.plan.map((d, i) => (
                        <circle
                        key={'sprint-plan-point' + i}
                        r={tooltipData && tooltipData.id === 'sprint-plan-point' + i ? 6 : 3}
                        cx={timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        cy={taskCountScale(d)}
                        stroke={targetColour}
                        fillOpacity={0.7}
                        fill={targetColour}
                        onMouseLeave={() => handleTooltipHide()}
                        onMouseOver={(event) => handleTooltip(event, d, i, 'Sprint Target', targetColour, 'sprint-plan-point' + i)}
                        />
                    ))}
                    <LinePath
                        data={sprintChartData.startLineData.actual}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke={actualColour}
                        strokeWidth={2}
                        strokeOpacity={0.8}
                        // strokeDasharray="1,2"
                    />
                    {sprintChartData.startLineData.actual.map((d, i) => (
                        <circle
                            key={'sprint-actual-point' + i}
                            r={tooltipData && tooltipData.id === 'sprint-actual-point' + i ? 6 : 3}
                            cx={timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                            cy={taskCountScale(d)}
                            stroke={actualColour}
                            fillOpacity={0.7}
                            fill={actualColour}
                            onMouseLeave={() => handleTooltipHide()}
                            onMouseMove={(event) => handleTooltip(event, d, i, 'Actual Starts', actualColour, 'sprint-actual-point' + i)}
                        />
                    ))}
                    <LinePath
                        data={sprintChartData.startLineData.upper}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke="#222"
                        strokeWidth={1.5}
                        strokeOpacity={0.2}
                        strokeDasharray="1,2"
                    />
                    <LinePath
                        data={sprintChartData.startLineData.lower}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke="#222"
                        strokeWidth={1.5}
                        strokeOpacity={0.2}
                        strokeDasharray="1,2"
                    />
                </Group> : <Group left={margin.left} top={margin.top}>
                    {/*<GridRows scale={taskCountScale} width={xMax + 60} left={0} height={yMax} stroke="#e0e0e0" />*/}
                    <GridColumns scale={timeScale} width={xMax} height={yMax} stroke="#e0e0e0" numTicks={7}/>
                    <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0" />
                    <AxisBottom top={yMax} scale={timeScale} numTicks={7} />
                    <AxisLeft scale={taskCountScale} />
                    <Threshold
                        id={`${Math.random()}`}
                        data={thresholdData}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y0={(d) => taskCountScale(d.lower) ?? 0}
                        y1={(d) => taskCountScale(d.upper) ?? 0}
                        clipAboveTo={0}
                        clipBelowTo={yMax}
                        // curve={curveBasis}
                        belowAreaProps={{
                            fill: averageColour,
                            fillOpacity: tooltipData && tooltipData.id === 'average' ? 0.4 : 0.2,
                            onMouseOver: (e) => handleTooltip(e, null, null, '12-week average achieved (±σ)', averageColour, 'average'),
                            onMouseLeave: () => handleTooltipHide()
                        }}
                    />
                    <LinePath
                        data={sprintChartData.finishLineData.average}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke={averageColour}
                        strokeWidth={tooltipData && tooltipData.id === 'average' ? 3 : 1.5}
                        strokeOpacity={0.8}
                        strokeDasharray="1,2"
                    />
                    <LinePath
                        data={sprintChartData.finishLineData.plan}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke={targetColour}
                        strokeWidth={2}
                        strokeOpacity={0.8}
                        // strokeDasharray="1,2"
                    />
                    {sprintChartData.finishLineData.plan.map((d, i) => (
                        <circle
                            key={'sprint-plan-point' + i}
                            r={tooltipData && tooltipData.id === 'sprint-plan-point' + i ? 6 : 3}
                            cx={timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                            cy={taskCountScale(d)}
                            stroke={targetColour}
                            fillOpacity={0.7}
                            fill={targetColour}
                            onMouseLeave={() => handleTooltipHide()}
                            onMouseOver={(event) => handleTooltip(event, d, i, 'Sprint Target', targetColour, 'sprint-plan-point' + i)}
                        />
                    ))}
                    <LinePath
                        data={sprintChartData.finishLineData.actual}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke={actualColourFinish}
                        strokeWidth={2}
                        strokeOpacity={0.8}
                        // strokeDasharray="1,2"
                    />
                    {sprintChartData.finishLineData.actual.map((d, i) => (
                        <circle
                            key={'sprint-actual-point' + i}
                            r={tooltipData && tooltipData.id === 'sprint-actual-point' + i ? 6 : 3}
                            cx={timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                            cy={taskCountScale(d)}
                            stroke={actualColourFinish}
                            fillOpacity={0.7}
                            fill={actualColourFinish}
                            onMouseLeave={() => handleTooltipHide()}
                            onMouseMove={(event) => handleTooltip(event, d, i, 'Actual Finishes', actualColourFinish, 'sprint-actual-point' + i)}
                        />
                    ))}
                    <LinePath
                        data={sprintChartData.finishLineData.upper}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke="#222"
                        strokeWidth={1.5}
                        strokeOpacity={0.2}
                        strokeDasharray="1,2"
                    />
                    <LinePath
                        data={sprintChartData.finishLineData.lower}
                        // curve={curveBasis}
                        x={(_, i) => timeScale(moment(currentSprintDate.toDate()).add(i, 'days').toDate())}
                        y={(d: number) => taskCountScale(d)}
                        stroke="#222"
                        strokeWidth={1.5}
                        strokeOpacity={0.2}
                        strokeDasharray="1,2"
                    />
                </Group>}
            </svg>
            {tooltipOpen && tooltipData && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                    <div
                        style={{ color: tooltipData.color }}
                    >
                        <strong>{tooltipData.key}</strong>
                    </div>
                    <div>{tooltipData.bar.data ? tooltipData.bar.data : ''}</div>
                    <div>
                        <small>{tooltipData.bar.date ? tooltipData.bar.date : ''}</small>
                    </div>
                </TooltipInPortal>
            )}
        </div>
    );
}